import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import {TextField , SelectField} from "../../../utils/utils"
import { useNavigate } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import ls from "../../../services/localStorageService";
// MUI Components
import {
    Grid,
    MenuItem,
} from '@mui/material';

import { JustifyBox } from "./style";

//API
import axios from "../../../config/api"
import { API_KEY } from "app/config/constant"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [leavecodes, setLeavecodes] = React.useState([]);
    const [noOfleave, setNoOfleave] = React.useState(0);
    const [remainLeave, setRemainLeave] = React.useState(0);
    const [takeOfleave, setTakeOfleave] = React.useState(1);
    const [state, setState] = React.useState({
        leavetype:'EL-EARNED LEAVE',
        fromdate:moment(new Date()).format("YYYY-MM-DD"),
        todate:moment(new Date()).format("YYYY-MM-DD"),
        noOfLeave:1,
        remark:''
    })
    const [leaveTitle,setLeaveTitle]=React.useState('EARN LEAVE')
    const [cannotLeave, setCannotLeave] = React.useState({
        status:false,
        message:''
    })
    //take leave only current month
    const [startDate, setStartDate] = React.useState(moment(new Date()).startOf('month').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = React.useState(moment(new Date()).endOf('month').format('DD/MM/YYYY'));
    
    const currentMember = ls.getItem('currentMember') || {}
    const { prefix } = API_KEY
    const leavecode_route = `/${prefix}/leavecode_info`
    function getLeaveCode(axios,constant_route) {
        return axios.get(constant_route)
        .then(function (response) {
            return response.data; // the response.data is string of src
        })
        .catch(function (response) {
            console.log(response);
        });
    }

    //take leave 
  function takeLeaveDay(axios,leave_post_route,data) {
    return axios.post(leave_post_route,data)
    .then(function (response) {
        return response.data; // the response.data is string of src
    })
    .catch(function (response) {
        console.log(response);
    });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // handle data
const handleChange = (event) => {
// event.persist()
    setState({
        ...state,
        [event.target.name]: event.target.value,
    })
}
const handleSelect =async (event) => {
        const short=event.target.value.split('-')[0];
        const long=event.target.value.split('-')[1];
        setLeaveTitle(long)
        // console.log(short)
    // event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
        const entitle_leave_route1 = `/${prefix}/entitleleave/noOfLeave?leavetypeid=${short}&membercode=${currentMember.membercode}`
        var noOfLeaves1=await getLeaveCode(axios,entitle_leave_route1)
        setRemainLeave(noOfLeaves1.data.length>0?noOfLeaves1.data[0].remain_leave:0)
        noOfLeaves1=noOfLeaves1.data.length>0?noOfLeaves1.data[0].entitleleave:0
        setNoOfleave(noOfLeaves1)
    }
  // handle date
const handleFromDate = (key, date) => {
    setState({
        ...state,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    let date2 = moment(state.fromdate, 'YYYY-MM-DD');
    let date1 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD');
    let daysDiff = date2.diff(date1, 'days');
    const tLeave=isNaN(daysDiff)?0:daysDiff+1
    setTakeOfleave(tLeave)

    const checkValid=noOfleave-tLeave
    if(checkValid<=0){
        setCannotLeave({
            status:true,
            message:"Can't leave"
        })
    }else{
        setCannotLeave({
            status:false,
            message:""
        })
    }
}
const handleToDate = (key, date) => {
    setState({
        ...state,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    let date1 = moment(state.fromdate, 'YYYY-MM-DD');
    let date2 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD');
    let daysDiff = date2.diff(date1, 'days');
    const tLeave=isNaN(daysDiff)?0:daysDiff+1
    setTakeOfleave(tLeave)

    const checkValid=remainLeave-tLeave
    if(checkValid<0){
        setCannotLeave({
            status:true,
            message:`You can't take leave greater than remain leave (${remainLeave} days)`
        })
    }else{
        setCannotLeave({
            status:false,
            message:""
        })
    }
}

const handleFrom = () => {

}
const handleSubmit = async () => {
    var leav_obj={
        ...state,
        membernoid:currentMember.id,
        membercode:currentMember.membercode,
        attendancemonth:state.fromdate.substring(0,7),
        noOfLeave:takeOfleave
    }
    
    const leaveday_post_route = `/${prefix}/entitleleave/memberLeave`
    const leave_day=await takeLeaveDay(axios,leaveday_post_route,leav_obj)
    setOpen(false);
    navigate('/')
}
React.useEffect(async () => {
    const leaveCodes=await getLeaveCode(axios,leavecode_route)

    // console.log(state)
    const entitle_leave_route = `/${prefix}/entitleleave/noOfLeave?leavetypeid=${state.leavetype.split('-')[0]}&membercode=${currentMember.membercode}`
    var noOfLeaves=await getLeaveCode(axios,entitle_leave_route)
    setRemainLeave(noOfLeaves.data.length>0?noOfLeaves.data[0].remain_leave:0)
    noOfLeaves=noOfLeaves.data.length>0?noOfLeaves.data[0].entitleleave:0
    setNoOfleave(noOfLeaves)
    setLeavecodes(leaveCodes.leavecode_info)
}, []);
// console.log(state)
  return (
    <div>
      <Button 
        // variant="outlined"
        size="small"
        color="primary" onClick={handleClickOpen}>
        Take Leave
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // sx={{ paddingLeft:'250px'} }
        sx={isMobile?{ paddingLeft:'0px'}:{ paddingLeft:'250px'} }
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Taking Leave Days
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <ValidatorForm onSubmit={handleFrom}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            What Leave Will You Take?
                        </Typography>
                        <SelectField
                            type="select"
                            size="small"
                            name="leavetype"
                            value={state.leavetype || ''}
                            onChange={handleSelect}
                        >
                            <MenuItem value="">-- Select Leave Type --</MenuItem>
                            {
                                leavecodes && leavecodes?.map((leave) => {
                                    return (
                                        <MenuItem
                                            key={`${leave.leaveid}`}
                                            value={`${leave.leaveid}-${leave.description}`}
                                        >
                                            {leave.description}
                                        </MenuItem>
                                    )
                                })
                            }
                        </SelectField>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography gutterBottom>
                            Entitle {leaveTitle} Days : 
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Typography gutterBottom>
                            Taking {leaveTitle} Days : 
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography 
                            sx={{
                                fontSize: 30,
                            }} 
                            color='red'
                            >{remainLeave}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Typography 
                            sx={{
                                fontSize: 30,
                            }} 
                            color='red'
                            >{takeOfleave==0?0:-takeOfleave}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={state.fromdate}
                                onChange={(date) => handleFromDate('fromdate', date)}
                                minDate={startDate}
                                // maxDate={endDate}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        variant="outlined"
                                        id="mui-pickers-date"
                                        name="fromdate"
                                        label="From Date"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={ state.todate}
                                onChange={(date) => handleToDate('todate', date)}
                                minDate={startDate}
                                // maxDate={endDate}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        variant="outlined"
                                        id="mui-pickers-date"
                                        name="todate"
                                        label="To Date"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {cannotLeave.status?
                        <Grid item xs={12}>
                            <Typography 
                                sx={{
                                    fontSize: 15,
                                    textAlign:'center'
                                }} 
                                color='red'
                            >
                                {cannotLeave.message}
                            </Typography>
                        </Grid>:''
                    }
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            Remark
                        </Typography>
                        <TextField
                            label="Remark *"
                            type="text"
                            size="small"
                            name="remark"
                            onChange={handleChange}
                            value={state.remark || ''}
                            // validators={[]}
                            // errorMessages={['this field is required']}
                        />
                    </Grid>
                </Grid>
            </ValidatorForm>
        </DialogContent>
        <JustifyBox>
            <DialogActions>
                <Button 
                    variant="contained"
                    color="primary" 
                    disabled={cannotLeave.status || noOfleave==0?true:false}
                    onClick={handleSubmit}>
                    Save
                </Button>
                <Button 
                    variant="outlined" onClick={handleClose}>
                Cancel
                </Button>
            </DialogActions>
        </JustifyBox> 
      </BootstrapDialog>
    </div>
  );
}