import {
    POST_NEW_CHECKIN_MEMBER,
    GET_CHECKIN_MEMBER_LIST,
    GET_CHECKIN_MEMBER_BY_ID,
    PUT_CHECKIN_MEMBER_BY_ID,
    DEL_CHECKIN_MEMBER_BY_ID
} from '../actions/MemberCheckInActions'

const initialState = {
    result: {},
}

const checkInMemberReducer = function (state = initialState, action) {
    switch (action.type) {
        case POST_NEW_CHECKIN_MEMBER: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case GET_CHECKIN_MEMBER_LIST: {
            return {
                ...state,
                result: { data: action?.payload?.data },
            }
        }
        case GET_CHECKIN_MEMBER_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case PUT_CHECKIN_MEMBER_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case DEL_CHECKIN_MEMBER_BY_ID: {
            return {
                ...state,
                result: {
                    data: state.result?.data.filter(
                        item => item?.id !== action?.payload?.data?.id
                    )
                },
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default checkInMemberReducer
