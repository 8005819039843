import React, { lazy } from 'react'
import Loadable from 'app/pages/components/Loadable/Loadable'
// import { authRoles } from 'app/pages/auth/authRoles'

const AppEchart = Loadable(lazy(() => import('./echarts/AppEchart')))

const chartsRoute = [
    {
        path: '/charts/echarts',
        element: <AppEchart />,
        // auth: authRoles.admin,
    },
]

export default chartsRoute
