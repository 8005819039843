import axios from "../../config/api"
import { API_KEY } from "app/config/constant"

export const POST_NEW_PERMISSION = 'POST_NEW_PERMISSION'
export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST'
export const GET_PERMISSION_BY_ID = 'GET_PERMISSION_BY_ID'
export const PUT_PERMISSION_BY_ID = 'PUT_PERMISSION_BY_ID'
export const DEL_PERMISSION_BY_ID = 'DEL_PERMISSION_BY_ID'

const { prefix, v1, permissions } = API_KEY
const constant_route = `/${prefix}/${v1}/${permissions}`

export const postNewPermission = (data) => (dispatch) => {
    axios.post(constant_route, data).then((res) => {
        dispatch({
            type: POST_NEW_PERMISSION,
            payload: res.data,
        })
    })
}

export const getPermissionList = () => (dispatch) => {
    axios.get(constant_route).then((res) => {
        dispatch({
            type: GET_PERMISSION_LIST,
            payload: res.data,
        })
    })
}

export const getPermissionById = (id) => (dispatch) => {
    axios.get(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: GET_PERMISSION_BY_ID,
            payload: res.data,
        })
    })
}

export const putPermissionById = (data) => (dispatch) => {
    axios.put(constant_route, data).then((res) => {
        dispatch({
            type: PUT_PERMISSION_BY_ID,
            payload: res.data,
        })
    })
}

export const delPermissionById = (id) => (dispatch) => {
    axios.delete(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: DEL_PERMISSION_BY_ID,
            payload: res.data,
        })
    })
}