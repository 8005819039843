import axios from "../../config/api"
import { API_KEY } from "app/config/constant"

export const POST_NEW_PHOTO = 'POST_NEW_PHOTO'
export const GET_PHOTO_LIST = 'GET_PHOTO_LIST'
export const GET_PHOTO_BY_ID = 'GET_PHOTO_BY_ID'
export const PUT_PHOTO_BY_ID = 'PUT_PHOTO_BY_ID'
export const DEL_PHOTO_BY_ID = 'DEL_PHOTO_BY_ID'

const { prefix, v1, photos } = API_KEY
const constant_route = `/${prefix}/${v1}/${photos}`

export const postNewPhoto = (data) => (dispatch) => {
    axios.post(constant_route, data).then((res) => {
        dispatch({
            type: POST_NEW_PHOTO,
            payload: res.data,
        })
    })
}

export const getPhotoList = () => (dispatch) => {
    axios.get(constant_route).then((res) => {
        dispatch({
            type: GET_PHOTO_LIST,
            payload: res.data,
        })
    })
}

export const getPhotoById = (id) => (dispatch) => {
    axios.get(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: GET_PHOTO_BY_ID,
            payload: res.data,
        })
    })
}

export const putPhotoById = (data) => (dispatch) => {
    axios.put(constant_route, data).then((res) => {
        dispatch({
            type: PUT_PHOTO_BY_ID,
            payload: res.data,
        })
    })
}

export const delPhotoById = (id) => (dispatch) => {
    axios.delete(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: DEL_PHOTO_BY_ID,
            payload: res.data,
        })
    })
}