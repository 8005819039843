import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {isMobile} from 'react-device-detect';
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'
import { useTheme } from '@mui/system'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { TextField , SelectField, AutoComplete } from "../../../utils/utils"
import { useNavigate } from 'react-router-dom'
import TrpiMember from "./TripMemberTable"
import ls from "../../../services/localStorageService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// MUI Components
import {
    Grid,
    MenuItem,
} from '@mui/material';

import { JustifyBox } from "./style";

//API
import axios from "../../../config/api"
import { API_KEY } from "app/config/constant"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
    const ref0 = React.useRef();
    const navigate = useNavigate()
    const { palette } = useTheme()
    const textError = palette.error.main
    const [open, setOpen] = React.useState(false);
    const [groupList, setGroupList] = React.useState([]);
    const [competitorList, setCompetitorList] = React.useState([]);
    const [carCodeList, setCarCodeList] = React.useState([]);
    const [stateIdList, setStateIdList] = React.useState([]);
    const [arrivalDisabled,setArrivalDisabled]=React.useState(true);
    const [triptTypeList, setTripTypeList] = React.useState([]);
    const [tripMember, setTripMember] = React.useState([]);
    const [requiredData, setRequiredField] = React.useState({})
    // const [period, setPeriod] = React.useState(1);
    const [state, setState] = React.useState({
        trip_assignmentdate:moment(new Date()).format("DD/MM/YYYY"),
        triptypeid:'',
        stateid :'',
        groupno :'',
        carcode:'',
        milelist:'',
        copetitor:'',
        departuredate :moment(new Date()).format("YYYY-MM-DD"),
        arrivaldate :moment(new Date()).format("YYYY-MM-DD"),
        period :1
    })
    const { prefix } = API_KEY
    //getDate
    function getData(axios,constant_route) {
        return axios.get(constant_route)
        .then(function (response) {
            return response.data; // the response.data is string of src
        })
        .catch(function (response) {
            console.log(response);
        });
    }
   //take Trip Assignment 
   function takeTripAssign(axios,trip_post_route,data) {
        return axios.post(trip_post_route,data)
            .then(function (response) {
                return response.data; // the response.data is string of src
            })
            .catch(function (response) {
                console.log(response);
            });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

const handleChange = (event) => {
// event.persist()
    setState({
        ...state,
        [event.target.name]: event.target.value,
    })
}
const handleDate = (key, date) => {
    setState({
        ...state,
        [key]: moment(date).format("YYYY-MM-DD")
    })
}
  // handle date
const handleDepartureDate = (key, date) => {
    setRequiredField({
        ...requiredData,
        [key]: false
    })
    let date2 = moment(state.arrivaldate, 'YYYY-MM-DD');
    let date1 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD');
    let daysDiff = date2.diff(date1, 'days');
    const period=isNaN(daysDiff)?0:daysDiff+1
    setState({
        ...state,
        period:period,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    setArrivalDisabled(false)
}
const handleArrivalDate = (key, date) => {
    setRequiredField({
        ...requiredData,
        [key]: false
    })
    let date1 = moment(state.departuredate, 'YYYY-MM-DD');
    let date2 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD');
    let daysDiff = date2.diff(date1, 'days');
    const period=isNaN(daysDiff)?0:daysDiff+1
    setState({
        ...state,
        period:period,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    // setPeriod(tPeriod)
}

const handleFrom = () => {

}
const handleSubmit = async () => {
    
    setState({
        ...state,
        memberList:tripMember.rows,
    })
    let trip_obj={
        ...state,
        memberList:tripMember.rows,
    }
    let obj = {
        triptypeid: (trip_obj.triptypeid && trip_obj.triptypeid !== '') ? false : true,
        stateid: (trip_obj.stateid && trip_obj.stateid !== '') ? false : true,
        groupno: (trip_obj.groupno && trip_obj.groupno !== '') ? false : true,
        departuredate: (trip_obj.departuredate && trip_obj.departuredate !== '') ? false : true,
        arrivaldate: (trip_obj.arrivaldate && trip_obj.arrivaldate !== '') ? false : true,
    }
    const someTruthy = Object.values(obj).some(val => val === true);
    if (someTruthy) {
        setRequiredField({
            ...obj
        })
    } else {
        const trip_post_route = `/${prefix}/tripassignment/add`
        const trip_assigment=await takeTripAssign(axios,trip_post_route,trip_obj)
        console.log(trip_assigment)
        setOpen(false);
        navigate('/')
    }
}
const handleAutoCompleteChange = (event, newValue,key) => {
    if (newValue) {
        setRequiredField({
            ...requiredData,
            [key]: false
        })
        if(key=='stateid'){   
            setState({
                ...state,
                [key]:newValue?newValue.merge_state:'',
                milelist:newValue?newValue.miles:'',
            })
        }else{
            setState({
                ...state,
                [key]:newValue?newValue[key]:''
            })
        } 
    }else{
        if(key=='stateid'){   
            delete state[key]
            delete state.milelist
            setState({
                ...state
            })
        }else{
            delete state[key]
            setState({
                ...state
            })
        }
    }
} 
React.useEffect(async () => {
    //group no
    const group_route = `/${prefix}/group_info`
    const groups=await getData(axios,group_route)||{}
    setGroupList(groups.group_info)

    //car code
    const car_code_route = `/${prefix}/carcode_info`
    const carcodes=await getData(axios,car_code_route)||{}
    setCarCodeList(carcodes.carcode_info)

    //state Id
    const triptype=state?.triptypeid || 'all';
    const state_id_route = `/${prefix}/tripassignment/getStateID?triptypeid=${triptype}`
    const stateIds=await getData(axios,state_id_route)||{}
    setStateIdList(stateIds.state_id)

    //trip type
    const trip_type_route = `/${prefix}/triptype_info`
    const triptypes=await getData(axios,trip_type_route)||{}
    setTripTypeList(triptypes.triptype_info)

    //competitor
    const competitor_route = `/${prefix}/competitor`
    const competitors=await getData(axios,competitor_route)||{}
    setCompetitorList(competitors.competitor)
}, [state]);
const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "red"
            }
          }
        }
      }
    }
  });
  return (
    <div>
      <Button 
        // variant="outlined"
        size="small"
        color="primary" onClick={handleClickOpen}>
        Trip Assignment
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'lg'}
        sx={isMobile?{ paddingLeft:'0px'}:{ paddingLeft:'250px'} }
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Trip Assignment
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <ValidatorForm onSubmit={handleFrom}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <Typography gutterBottom>
                                    Trip Assignment No:
                                </Typography>
                                <Typography  
                                    sx={{
                                        fontSize: 15,
                                        fontWeight:'bold'
                                    }} >
                                    
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Typography gutterBottom>
                                        Date
                                    </Typography>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="trip_assignmentdate"
                                        // onChange={handleChange}
                                        readOnly={true}
                                        value={state.trip_assignmentdate || ''}
                                        validators={[]}
                                        errorMessages={['this field is required']}
                                    />
                                    {/* <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={state.trip_assignmentdate}
                                        // onChange={(date) => handleDate('trip_assignmentdate', date)}
                                        // minDate={startDate}
                                        // maxDate={endDate}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                size="small"
                                                variant="outlined"
                                                id="mui-pickers-date"
                                                name="trip_assignmentdate"
                                                // label="Date"
                                            />
                                        )}
                                    /> */}
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <Typography gutterBottom>
                                            Trip Type :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography color="error.main">*</Typography>
                                    </Grid>
                                </Grid>
                                <ThemeProvider theme={ (requiredData.triptypeid && requiredData.triptypeid == true) ? theme :''}>
                                    <AutoComplete
                                        onChange={(event,value)=>(handleAutoCompleteChange(event,value,'triptypeid'))}
                                        options={triptTypeList || []}
                                        size="small"
                                        name="triptypeid"
                                        sx={{ borderColor: (requiredData.triptypeid && requiredData.triptypeid == true) ? 'error.main' : '' }}
                                        defaultValue={{ triptypeid:state.triptypeid || '' }}
                                        getOptionLabel={(option) => option.triptypeid}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Trip Type *"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography gutterBottom>
                                            State ID :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography color="error.main">*</Typography>
                                    </Grid>
                                </Grid>
                                <ThemeProvider theme={ (requiredData.stateid && requiredData.stateid == true) ? theme :''}>
                                    <AutoComplete
                                        onChange={(event,value)=>(handleAutoCompleteChange(event,value,'stateid'))}
                                        options={stateIdList || []}
                                        size="small"
                                        name="stateid"
                                        sx={{ borderColor: (requiredData.stateid && requiredData.stateid == true) ? 'error.main' : '' }}
                                        defaultValue={{ merge_state:state.stateid || '' }}
                                        getOptionLabel={(option) => option.merge_state}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State Id *"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography gutterBottom>
                                    Car No:
                                </Typography>
                                <AutoComplete
                                    onChange={(event,value)=>(handleAutoCompleteChange(event,value,'carcode'))}
                                    options={carCodeList || []}
                                    size="small"
                                    name="carcode"
                                    defaultValue={{ carcode:state.carcode || '' }}
                                    getOptionLabel={(option) => option.carcode}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Car Code *"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>
                                    Miles List:
                                </Typography>
                                <TextField
                                    type="text"
                                    size="small"
                                    name="milelist"
                                    onChange={handleChange}
                                    value={state.milelist || ''}
                                    validators={[]}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                            
                            <Grid item xs={5}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={7}>
                                            <Typography gutterBottom>
                                            Departure Date :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography color="error.main">*</Typography>
                                        </Grid>
                                    </Grid>
                                    <ThemeProvider theme={ (requiredData.departuredate && requiredData.departuredate == true) ? theme :''}>
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            value={state.departuredate}
                                            sx={{ borderColor: (requiredData.departuredate && requiredData.departuredate == true) ? 'error.main' : '' }}
                                            onChange={(date) => handleDepartureDate('departuredate', date)}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    size="small"
                                                    variant="outlined"
                                                    id="mui-pickers-date"
                                                    name="departuredate"
                                                />
                                            )}
                                        />
                                    </ThemeProvider>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <Typography gutterBottom>
                                            Arrival Date :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography color="error.main">*</Typography>
                                        </Grid>
                                    </Grid>
                                    <ThemeProvider theme={ (requiredData.arrivaldate && requiredData.arrivaldate == true) ? theme :''}>
                                        <DatePicker
                                            disabled={arrivalDisabled}
                                            inputFormat="dd/MM/yyyy"
                                            value={state.arrivaldate}
                                            onChange={(date) => handleArrivalDate('arrivaldate', date)}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    size="small"
                                                    variant="outlined"
                                                    id="mui-pickers-date"
                                                    name="arrivaldate"
                                                />
                                            )}
                                        />
                                    </ThemeProvider>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography gutterBottom>
                                    Period:
                                </Typography>
                                <TextField
                                    type="text"
                                    size="small"
                                    name="period"
                                    onChange={handleChange}
                                    value={state.period || ''}
                                    validators={[]}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <Typography gutterBottom>
                                        Group No :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography color="error.main">*</Typography>
                                    </Grid>
                                </Grid>
                                <ThemeProvider theme={ (requiredData.groupno && requiredData.groupno == true) ? theme :''}>
                                    <AutoComplete
                                        onChange={(event,value)=>(handleAutoCompleteChange(event,value,'groupno'))}
                                        options={groupList || []}
                                        size="small"
                                        name="groupno"
                                        defaultValue={{ groupno:state.groupno || '' }}
                                        getOptionLabel={(option) => option.groupno}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Group No *"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography gutterBottom>
                                    Competitor:
                                </Typography>
                                <AutoComplete
                                    onChange={(event,value)=>(handleAutoCompleteChange(event,value,'competitor'))}
                                    options={competitorList || []}
                                    size="small"
                                    name="competitor"
                                    defaultValue={{ competitor:state.competitor || '' }}
                                    getOptionLabel={(option) => option.competitor}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Competitor *"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                    <Grid item item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TrpiMember setTripMember={setTripMember}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </DialogContent>
        <JustifyBox>
            <DialogActions>
                <Button 
                    variant="contained"
                    color="primary" 
                    // disabled={cannotLeave.status || noOfleave==0?true:false}
                    onClick={handleSubmit}>
                    Save
                </Button>
                <Button 
                    variant="outlined" onClick={handleClose}>
                Cancel
                </Button>
            </DialogActions>
        </JustifyBox> 
      </BootstrapDialog>
    </div>
  );
}