import {
    POST_NEW_PHOTO,
    GET_PHOTO_LIST,
    GET_PHOTO_BY_ID,
    PUT_PHOTO_BY_ID,
    DEL_PHOTO_BY_ID
} from '../actions/PhotoActions'

const initialState = {
    result: {},
}

const PhotoReducer = function (state = initialState, action) {
    switch (action.type) {
        case POST_NEW_PHOTO: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case GET_PHOTO_LIST: {
            return {
                ...state,
                result: { data: action?.payload?.data },
            }
        }
        case GET_PHOTO_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case PUT_PHOTO_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case DEL_PHOTO_BY_ID: {
            return {
                ...state,
                result: {
                    data: state.result?.data.filter(
                        item => item?.id !== action?.payload?.data?.id
                    )
                },
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default PhotoReducer
