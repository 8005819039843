import React, { lazy } from 'react'
import Loadable from 'app/pages/components/Loadable/Loadable'
// import { authRoles } from '../../pages/auth/authRoles'

const Analytics = Loadable(lazy(() => import('./Analytics')))

const PermissionTable = Loadable(lazy(() => import('./permission/RootTable')))
const PermissionForm = Loadable(lazy(() => import('./permission/RootForm')))

const UserTable = Loadable(lazy(() => import('./user/RootTable')))
const UserForm = Loadable(lazy(() => import('./user/RootForm')))

const PhotoTable = Loadable(lazy(() => import('./photo/RootTable')))
const PhotoForm = Loadable(lazy(() => import('./photo/RootForm')))

const CheckinMemberTable = Loadable(lazy(() => import('./checkinmember/RootTable')))
const CheckinMemberForm = Loadable(lazy(() => import('./checkinmember/RootForm')))
const Calendar= Loadable(lazy(()=>import('./calendar/RootCalendar')))
const TakeLeave= Loadable(lazy(()=>import('./memberleave/RootForm')))
const TripAssignment= Loadable(lazy(()=>import('./tripassignment/RootForm')))

const AttendanceSummary = Loadable(lazy(() => import('./attendance_summary/RootTable')))

const SettingForm = Loadable(lazy(() => import('./profile/RootSetting')))

const MemberProfile=Loadable(lazy(() => import('./profile/RootTable')));

const dashboardRoutes = [
    {
        path: '/dashboard/public_holiday',
        element: <Calendar />,
        // auth: authRoles.admin,
    },
    {
        path: '/dashboard/checkinmember',
        element: <CheckinMemberTable />,
        // auth: authRoles.admin,
    },
    {
        path: '/dashboard/checkinmember/new',
        element: <CheckinMemberForm />,
        // auth: authRoles.admin,
    },
    {
        path: '/dashboard/member-profile',
        element: <MemberProfile />,
        // auth: authRoles.admin,
    },
    {
        path: '/dashboard/member-setting',
        element: <SettingForm />,
        // auth: authRoles.admin,
    },
    {
        path: '/dashboard/attendance_summary',
        element: <AttendanceSummary />,
    },
    {
        path:'/dashboard/take_leave',
        element:<TakeLeave/>
    },
    {
        path:'/dashboard/trip_assignment',
        element:<TripAssignment/>
    }
    // {
    //     path: '/collections/permissions/new',
    //     element: <PermissionForm />,
    // },
    // {
    //     path: '/collections/permissions/edit/:id',
    //     element: <PermissionForm />,
    // },
    // {
    //     path: '/collections/users',
    //     element: <UserTable />,
    // },
    // {
    //     path: '/collections/users/new',
    //     element: <UserForm />,
    // },
    // {
    //     path: '/collections/users/edit/:id',
    //     element: <UserForm />,
    // },
    // {
    //     path: '/collections/photos',
    //     element: <PhotoTable />,
    // },
    // {
    //     path: '/collections/photos/new',
    //     element: <PhotoForm />,
    // },
    // {
    //     path: '/collections/photos/edit/:id',
    //     element: <PhotoForm />,
    // }
]

export default dashboardRoutes
