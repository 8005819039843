import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AllPages } from '../../routes/routes'
import { flat } from 'app/utils/utils'
import useAuth from 'app/hooks/useAuth'
// import AppContext from '../../contexts/AppContext'

// Check here to filter prevent routes components

// const getUserRoleAuthStatus = (pathname, user, routes) => {
//     if (!user) {
//         return false
//     }

//     const matched = routes.find((r) => r.path === pathname)

//     const authenticated =
//         matched && matched.auth && matched.auth.length
//             ? matched.auth.includes(user.role)
//             : true

//     return authenticated
// }

const AuthGuard = ({ children }) => {
    const { isAuthenticated, member } = useAuth()
    // return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    const routes = flat(AllPages())

    // const isUserRoleAuthenticated = getUserRoleAuthStatus(
    //     pathname,
    //     member,
    //     routes
    // )

    let authenticated = isAuthenticated 

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    // let authenticated = isAuthenticated

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute]);

    if (authenticated) return <>{children}</>
    else {
        return (
            <Navigate
                to="/session/signin"
                state={{ redirectUrl: previouseRoute }}
            />
            // <Redirect
            //     to={{
            //         pathname: '/session/signin',
            //         state: { redirectUrl: previouseRoute },
            //     }}
            // />
        )
    }
}

export default AuthGuard
