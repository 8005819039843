export const API_URL = 'https://hr.gcmyanmar.co'
export const API_KEY = {
    v1: 'v1',
    prefix: 'api',
    files: 'files',
    users: 'users',
    photos: 'photos',
    membercode: 'membercode',
    membercheckin: 'membercheckin',
    permissions: 'permissions',
    location: 'location',
    getToken: 'auth/getToken',
    register: 'users/register',
    login: 'users/login',
}
export const PERMISSION = [{
        name: 'users',
        read: false,
        write: false,
        delete: false,
    },
    {
        name: 'photos',
        read: false,
        write: false,
        delete: false,
    },
    {
        name: 'permissions',
        read: false,
        write: false,
        delete: false,
    },
]
export const AUTHS = [{
        role: 'SA',
        name: 'Super Admin',
        email: 'superadmin.n3t@gmail.com',
        pwdHash: '$2a$12$PGtVkpeGUUU3Hv9Bvjx5cehqsZWhkLMc6wsqZEtR6mX5evATfB.pa',
        pwdRaw: '5u93r4d3in.p455w0rd',
        id: 571601490660,
    },
    {
        role: 'MG',
        name: 'Manager',
        email: 'manager.n3t@gmail.com',
        pwdHash: '$2a$12$NmEK00gsyBTneqY5ib4SNOem5ZmI3n7iSFnvPChIQDw2jSkACFe1e',
        pwdRaw: 'm@n49er.p455w0rd',
        id: 571601490661,
    },
    {
        role: 'SV',
        name: 'Supervisor',
        email: 'supervisor.n3t@gmail.com',
        pwdHash: '$2a$12$nrcVKLxR6BQQdG8EkQ.qa.7qNAt.PKBhKScrhQpUtFHiceihGNiUO',
        pwdRaw: '5up3rv1s0r.p455w0rd',
        id: 571601490662,
    },
    {
        role: 'ET',
        name: 'Editor',
        email: 'editor.n3t@gmail.com',
        pwdHash: '$2a$12$nrcVKLxR6BQQdG8EkQ.qa.7qNAt.PKBhKScrhQpUtFHiceihGNiUO',
        pwdRaw: '5up3rv1s0r.p455w0rd',
        id: 571601490663,
    },
]
export const SRVER_AUTH = {
    CREDENTIAL_USERNAME: 'lBz_a30qLgHUDQhblA8k2bqLuP4ezZBwbqgce49qeZg',
    CREDENTIAL_PASSWORD: 'pDXSown7DtlOwmuK',
}
export const tableOptions = {
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    selectableRows: false,
    filterType: 'dropdown',
    responsive: 'vertical',
}