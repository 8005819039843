export const HANDLE_RESPONSE_DATA = 'HANDLE_RESPONSE_DATA'

const initialState = {
    result: {},
}

const ResponseReducer = function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_RESPONSE_DATA: {
            return {
                ...state,
                result: { data: action?.payload },
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ResponseReducer
