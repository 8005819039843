import {
    POST_NEW_USER,
    GET_USER_LIST,
    GET_USER_BY_ID,
    PUT_USER_BY_ID,
    DEL_USER_BY_ID
} from '../actions/UserActions'

const initialState = {
    result: {},
}

const UserReducer = function (state = initialState, action) {
    switch (action.type) {
        case POST_NEW_USER: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case GET_USER_LIST: {
            return {
                ...state,
                result: { data: action?.payload?.data },
            }
        }
        case GET_USER_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case PUT_USER_BY_ID: {
            return {
                ...state,
                result: { data: [action?.payload?.data] },
            }
        }
        case DEL_USER_BY_ID: {
            return {
                ...state,
                result: {
                    data: state.result?.data.filter(
                        item => item?.id !== action?.payload?.data?.id
                    )
                },
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default UserReducer
